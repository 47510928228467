import { ReactElement, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import URL from "constant/url";
import MainLayout from "layouts/MainLayout";
import Content from "layouts/Content";
import Agreement from "pages/Agreement";
import Contact from "pages/Contact";
import Guide from "pages/Guide";
import Guide1 from "pages/Guide/Guide1";
import Guide2 from "pages/Guide/Guide2";
import Guide3 from "pages/Guide/Guide3";
import SLA from "pages/SLA";
import SLADetail from "pages/SLADetail";

const ListServerDetail = lazy(() => import("components/ListServerDetail"));
const NotFound = lazy(() => import("pages/NotFound"));
const ListProxyDetail = lazy(() => import("components/ListProxyDetail"));
const ListDedicatedServer = lazy(
  () => import("components/ListDedicatedServerDetail")
);
const List922Proxy = lazy(() => import("components/List922Proxy"))
const ListPIAProxy = lazy(() => import("components/ListPIAProxy"))

interface ItemType {
  key: string;
  layout: string;
  components: ReactElement;
}

export default function Routers() {
  const routerItems: ItemType[] = [
    {
      key: URL.ListServerVN,
      layout: "main",
      components: <ListServerDetail />,
    },
    {
      key: URL.ListServerSG,
      layout: "main",
      components: <ListServerDetail />,
    },
    {
      key: URL.ListServerAU,
      layout: "main",
      components: <ListServerDetail />,
    },
    {
      key: URL.ListServerUK,
      layout: "main",
      components: <ListServerDetail />,
    },
    {
      key: URL.ListServerUS,
      layout: "main",
      components: <ListServerDetail />,
    },
    {
      key: URL.ListServerDE,
      layout: "main",
      components: <ListServerDetail />,
    },
    {
      key: URL.ListServerFR,
      layout: "main",
      components: <ListServerDetail />,
    },
    {
      key: URL.ListServerCA,
      layout: "main",
      components: <ListServerDetail />,
    },
    {
      key: URL.ListServerEU,
      layout: "main",
      components: <ListServerDetail />,
    },
    {
      key: URL.ListServerNL,
      layout: "main",
      components: <ListServerDetail />,
    },
    {
      key: URL.ListVPSGPU,
      layout: "main",
      components: <ListServerDetail />,
    },
    {
      key: URL.ListProxyVN,
      layout: "main",
      components: <ListProxyDetail />,
    },
    {
      key: URL.ListProxyAU,
      layout: "main",
      components: <ListProxyDetail />,
    },
    {
      key: URL.ListProxyCA,
      layout: "main",
      components: <ListProxyDetail />,
    },
    {
      key: URL.ListProxyDE,
      layout: "main",
      components: <ListProxyDetail />,
    },
    {
      key: URL.ListProxyFR,
      layout: "main",
      components: <ListProxyDetail />,
    },
    {
      key: URL.ListProxyNL,
      layout: "main",
      components: <ListProxyDetail />,
    },
    {
      key: URL.ListProxySG,
      layout: "main",
      components: <ListProxyDetail />,
    },
    {
      key: URL.ListProxyUK,
      layout: "main",
      components: <ListProxyDetail />,
    },
    {
      key: URL.ListProxyUS,
      layout: "main",
      components: <ListProxyDetail />,
    },
    {
      key: URL.ListDedicatedServerEU,
      layout: "main",
      components: <ListDedicatedServer />,
    },
    {
      key: URL.List922S5Proxy,
      layout: "main",
      components: <List922Proxy />,
    },
    {
      key: URL.ListPIAS5Proxy,
      layout: "main",
      components: <ListPIAProxy />,
    },
    {
      key: URL.ListDedicatedServerUS,
      layout: "main",
      components: <ListDedicatedServer />,
    },
    {
      key: URL.SLA,
      layout: "main",
      components: <SLA />,
    },
    {
      key: URL.SLADetail,
      layout: "main",
      components: <SLADetail />,
    },
    {
      key: URL.Guide,
      layout: "main",
      components: <Guide />,
    },
    {
      key: URL.Guide1,
      layout: "main",
      components: <Guide1 />,
    },
    {
      key: URL.Guide2,
      layout: "main",
      components: <Guide2 />,
    },
    {
      key: URL.Guide3,
      layout: "main",
      components: <Guide3 />,
    },
    {
      key: URL.Agreement,
      layout: "main",
      components: <Agreement />,
    },
    {
      key: URL.Contact,
      layout: "main",
      components: <Contact />,
    },
    {
      key: URL.Home,
      layout: "main",
      components: <Content />,
    },
    {
      key: URL.NotFound,
      layout: "none",
      components: <NotFound />,
    },
  ];
  return (
    <Routes>
      {routerItems.map((item) => {
        let element = item.components;
        element = <Suspense fallback={null}>{element}</Suspense>;
        if (item.layout === "main") {
          element = <MainLayout>{element}</MainLayout>;
        }
        return <Route key={item.key} path={item.key} element={element} />;
      })}
    </Routes>
  );
}
